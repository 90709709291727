import React, { useRef, useEffect } from "react";
import { Container as MuiContainer, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import { Link as ScrollLink } from 'react-scroll';
import { useSprings, useSpring, animated } from 'react-spring';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import CallToAction from 'ui/components/CallToAction';
import Burger from 'react-css-burger';
import styled from 'styled-components';
import Logo from 'resources/img/Logo.png';
import Button from 'ui/components/Button';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import getDemoNotification from 'resources/img/getDemoNotification.png';
import navCta from 'resources/img/navCta.png';
import cactus from 'resources/img/cactus.png';
import BgLine from 'resources/img/linesXC';
import closeBtn from 'resources/icons/closeBlackButton.svg';
import RightArrow from 'resources/icons/right-arrow.svg';

const navMenuBreakpoints = {
  lg: 920,
  md: 680,
}
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeaderContainer = styled.div`
  padding: 27px 0;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: ${p => (p.position && p.position.y > 100 ? 'white' : 'transparent')};
  ${p => (p.position && p.position.y > 100 ? 'box-shadow: 0px 10px 20px #0000000D;' : '')};
  transition: all 0.2s;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    button {
      margin: 0;
    }
  }
`;

const HeaderLogo = styled(animated.img)`
  width: 144px;
  height: 47px;
`;

const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  & > * {
    margin-left: 40px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    min-width: 100vw;
    & > * {
      margin: 1rem 2rem;
      font-size: 32px;
    }

    & > :first-child {
      margin-top: 2rem;
    }

  }
`;

const DrawerWrapper = styled.div`
  padding: 36px;
  min-width: 100vw;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const NavMenuOpenContainer = styled.div`
  position: fixed;
  top: 77px;
  background: white;
  box-shadow: 5px 10px 30px #00000012;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  overflow: hidden;
`;


const NavMenuOpenContainerFooter = styled.div`
  width: 100%;
  background: #9DCCF7 0% 0% no-repeat padding-box;
  height: 20px;
  bottom: 0;
`;

const ProductMenuContainer = styled.div`
  opacity:1;
  padding: 50px 60px;
  display:flex;

  @media only screen and (max-width: ${navMenuBreakpoints.lg}px) {
    padding: 50px 60px 20px;
  }

  @media only screen and (max-width: ${navMenuBreakpoints.md}px) {
    flex-direction: column;
    padding: 50px 60px 0;
  }
`;

const ProductMenuImg = styled.img`
  width: 116px;
  height: 155px;
  position: absolute;
  right: 50px;
  bottom: 20px;

  @media only screen and (max-width: ${navMenuBreakpoints.lg}px) {
    display: none;
  }
`;

const NavCtaWrapper = styled.div`
  border: 1px solid #D1E4F7;
  border-radius: 10px;
  background: #EDF8FF;
  text-align: center;
  min-width: 315px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${navMenuBreakpoints.lg}px) {
    max-height: 317px
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 42px 1rem 24px;
    max-width: unset; 
    min-width: unset;
  }
`;

const NavCtaHeader = styled.h4`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-bottom: 18px;
    font-size: 24px;
  }
`;

const NavCtaDesc = styled.p`
  font-size: 14px;
  color: #757575;
  max-width: 212px;
  margin-bottom: 28px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-bottom: 23px
  }
`;

const NavCtaImg = styled.img`
  height: 100px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    width: 179px;
    height: 112px;
  }
`;

const Link = styled(({ active, ...others }) => <ScrollLink {...others} />)`
  &&& {
    font-size: 18px;
    color: ${p => p.active ? `#0A7EF9` : '#757575'};
    transition: all 0.3s ease-in-out;
    will-change: all;

    &:hover {
      cursor: pointer;
      color: #0A7EF9;
    }

    &:active {
      color: black;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      font-size: 24px;
    }
  }
`;

const ExternalLink = styled.a`
  &&& {
    font-size: 18px;
    color: #757575;
    transition: all 0.3s ease-in-out;
    will-change: all;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: #0A7EF9;
    }

    &:active {
      color: black;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      font-size: 24px;
    }
  }
`;


const DialogBody = styled.div`

  min-width: 536px ;
  position: relative;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    width: 100%;
    min-width: unset;
  }
`;

const DialogSecondaryText = styled.p`
  font-size: 12px;
  margin: 20px 0 0;
  text-align: center;
`;


const DialogImg = styled.img`
  width: calc(140px + 2rem);
  padding-left: 2rem;
  height: 90px;
  display: block;
  margin: 0 auto 40px; 
`;

const DialogHeader = styled.h4`
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: #191919;
  margin: 0;
`;

const DialogDescription = styled.p`
  text-align: center;
  font-size: 15px;
  color: #757575;
  margin: 20px 0 30px;
`;

const DialogCloseButton = styled.button`
  border: none;
  background: #ECEDEF 0% 0% no-repeat padding-box;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  top: 20px;
  right: 21px;
  cursor: pointer;
`;

const DialogCloseIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const DialogContainer = styled.div`
  max-width: 382px;
  margin: 50px auto 50px;
  padding: 0 1rem;
   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width: unset;
  }
`;

const DialogFooter = styled.div`
  width: 100%;
  background: #9DCCF7 0% 0% no-repeat padding-box;
  height: 20px;
  bottom: 0;
`;

const DialogLineImgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const DialogRightArrowIcon = styled.img`
  height: 12px;
  width: 12px;
  padding-top: 4px;
`;

const NavMenuSuperHeader = styled.a`
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  color: #191919;
  margin: 0 0 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    color: #0A7EF9;
  }
`;

const NavMenuItem = styled.div`
  margin-bottom: 30px;
  min-width: 185px;
  margin-left: ${p => p.marginLeft};
  @media only screen and (max-width: ${navMenuBreakpoints.lg}px) {
    margin-left: 0;
    ${p => p.isHideTablet && 'display: none'}
  }

  @media only screen and (max-width: ${navMenuBreakpoints.md}px) {
    margin-left: ${p => p.marginLeft};
  }
  
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-left: 20px;
    display: block;
  }
`;

const NavMenuHeader = styled.h3`
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  color: #191919;
  margin: 0 0 5px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 20px;
  }
`;

const NavMenuDesc = styled.p`
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #757575;
  opacity: 0.8;
  margin: 0 0 20px;
`;

const NavMenuLink = styled.a`
  text-align: left;
  font: Regular 15px/18px Product Sans;
  letter-spacing: 0;
  color: #4B4B4B;
  text-decoration: none;
  display: block;

  &:hover {
    color: #0A7EF9;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const NavCloseButton = styled.button`
  border: none;
  background: #ECEDEF 0% 0% no-repeat padding-box;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  top: 20px;
  right: 21px;
  cursor: pointer;
`;

const NavCloseIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const NavContainer = styled.div`
  min-width: 185px;
  margin-left: 60px;
  display: flex;
  flex-wrap: wrap;
  width: 450px;
  @media only screen and (max-width: ${navMenuBreakpoints.lg}px) {
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (max-width: ${navMenuBreakpoints.md}px) {
    margin-left: 0px;
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: nowrap;
    
  }
  &:nth-child(1) {
    display: none;
}
`;

const NavItem = styled.div`
  opacity: 1;
 
    &::after {
       ${p => p.active && `
          content: "▲";
          position: absolute;
          height: 100%;
          top: 17px;
          left: 30%;
          color: white;
          font-size: 22px;
          background: transparent;
          text-shadow: #00000021 0px -3px 4px;
      `}
    }
`;

const navItems = ['Product', 'Blog','Sign in', 'Get your demo'];

const LinkSettings = {
  spy: true,
  smooth: true,
  offset: 30,
  duration: 500,
};

const Header = () => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

 
  const [activeNavItem, setActiveNavItem] = React.useState({navItem: null, isOpen: false});
  const [burgerState, setBurgerState] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActiveNavItem({ navItem: null, isOpen: false });
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  let options = {
    throttle: 1,
    passive: true
  };
  let position = useWindowScrollPosition(options);

  useEffect(() => { 
    setActiveNavItem({ navItem: null, isOpen: false });
  }, [position]);

  const logoProps = useSpring({
    delay: 300,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });
  const [springs] = useSprings(navItems.length, i => ({
    from: { opacity: 0, transform: `translateY(50px)` },
    to: { opacity: 1, transform: `translateY(0px)` },
    delay: (i + 1) * 150,
  }));

  const handleClick = navItem => {
    if (navItem === 'Product') {
      setActiveNavItem({navItem, isOpen: !activeNavItem.isOpen});
    }
  };

  const handleBurger = active => {
    setBurgerState(active);
  };

  const renderDialog = () => {
    return (
      <Dialog onClose={() => setIsOpen(false)} aria-labelledby="simple-dialog-title" open={isOpen}>
        <DialogBody>
          <DialogContainer>
            <DialogCloseButton onClick={() => setIsOpen(false)}>
              <DialogCloseIcon src={closeBtn} alt="close button" />
            </DialogCloseButton>
            <DialogImg src={getDemoNotification} alt="get-your-demo" />
            <DialogHeader>Get your personalized demo with Insent</DialogHeader>
            <DialogDescription>
              Schedule a time with our team to learn how you can start building relationships with
              your prospects today.
           </DialogDescription>
            <CallToAction justify="center"/>
            <DialogSecondaryText>
              Already have an account? <Button onClick={() => { window.location.href = "signin" }} variant="link">
                Sign in <DialogRightArrowIcon src={RightArrow} alt="sign in"/>    
                 </Button>
            </DialogSecondaryText>
          </DialogContainer>
          {/* <DialogLineImgWrapper>
            <BgLine />
          </DialogLineImgWrapper> */}
         </DialogBody>
        
        <DialogFooter />
      </Dialog>
    );
  };

  const renderNavMenu = (navItem) => {
    switch(navItem) {
      case 'Product':
        return <>
          <Link active={activeNavItem.isOpen && (activeNavItem.navItem === 'Product')}>
            <NavItem active={activeNavItem.isOpen && (activeNavItem.navItem === 'Product')} onClick={(e) => handleClick(e.target.textContent)}>{navItem}</NavItem>
          </Link>
        </>
      case 'Blog':
        return <ExternalLink href='https://blog.insent.ai/' target="_blank" target="_blank">{navItem}</ExternalLink>
      case 'Sign in':
        return <Button onClick={() => { window.location.href = "signin" }} variant="outlined">{navItem}</Button>
      case 'Get your demo':
        return <Button onClick={() => setIsOpen(true)}>{navItem}</Button>
    }
  }
  const renderTableDesktop = () => {
    return (
      <TabletDesktop>
        <HeaderRow>
          <a href={"/"}><HeaderLogo src={Logo} alt="logo" style={logoProps} /></a>
          <HeaderLinks>
            {springs.map((props, i) => {
              return (
                <animated.div key={i} style={props}>
                  {renderNavMenu(navItems[i])}
                </animated.div>
              );
            })}
          </HeaderLinks>
        </HeaderRow>
        {activeNavItem.isOpen && activeNavItem.navItem === 'Product' && (
          <NavMenuOpenContainer ref={wrapperRef}>
            <ProductMenuContainer>
              <NavCtaWrapper>
                <NavCtaImg src={navCta} />
                <NavCtaHeader>Why human-first?</NavCtaHeader>
                <NavCtaDesc>Break-through with a personalized, human-first conversation</NavCtaDesc>
                <Button onClick={() => { window.location.href = "why-human-first" }}>Learn more</Button>
              </NavCtaWrapper>
              <NavContainer>
                {renderNavMenuItem()}
              </NavContainer>
              <ProductMenuImg src={cactus} alt="cactus" />
            </ProductMenuContainer>
            <NavMenuOpenContainerFooter />
          </NavMenuOpenContainer>
        )}
        
      </TabletDesktop>
    );
  };

  const navigationItemList = [{
    header: 'Features',
    description: "Breaking down the capabilities",
    links: [{ label: 'Qualify & engage', url: '/qualify-engage' }, { label: 'Converse & context', url: 'converse-context' }]
  },{
    header: 'Solutions',
    description: "Regain your funnel's strength",
      links: [{ label: 'Marketing teams', url: '/marketing-team' }, { label: 'Sales teams', url: '/sales-team' }, { label: 'Account-based marketing', url: '/abm' },{ label: 'Live Chat', url: '/live-chat' }]
  },{
    header: 'Integrations',
    description: 'Integrate with your everyday tech stack',
    links: [{ label: 'Marketo', url: '/marketo' }, ,{ label: 'Hubspot', url: '/hubspot' }, { label: 'Salesforce', url: '/salesforce' }]
  }]

  const renderNavMenuItem = () => { 
    return navigationItemList.map((item, itemIndex) => {
      const isLast = (navigationItemList.length - 1) === itemIndex;
      return (
        <NavMenuItem key={itemIndex} marginLeft={(itemIndex + 1) % 2 === 0 ? '50px' : '0'} isHideTablet={isLast}>
          <NavMenuHeader>{item.header}</NavMenuHeader>
          <NavMenuDesc>{item.description}</NavMenuDesc>
          {item.links.map((linkItem, linkItemIndex) => (
            <NavMenuLink key={linkItemIndex} href={linkItem.url}>{linkItem.label}</NavMenuLink>
          ))}
        </NavMenuItem>
      )
    });
  }
  const renderMobile = () => {
    return (
      <Mobile>
        <HeaderRow>
          <a href={"/"}><HeaderLogo src={Logo} alt="logo" style={logoProps} /></a>
          <Burger
            onClick={() => handleBurger(!burgerState)}
            active={burgerState}
            burger="emphatic"
            color="black"
          />
          <Drawer width="100%" anchor="right" open={burgerState}>
            <DrawerWrapper>
              <DrawerHeader>
                <a href={"/"}><HeaderLogo src={Logo} alt="logo" style={logoProps} /></a>
                <NavCloseButton onClick={() => handleBurger(!burgerState)}>
                  <NavCloseIcon src={closeBtn} alt="close button" />
                </NavCloseButton>
              </DrawerHeader>
              <div>
                <NavMenuSuperHeader>Product</NavMenuSuperHeader>
                {renderNavMenuItem()}
              </div>
              <NavMenuSuperHeader href="https://blog.insent.ai/">Blog</NavMenuSuperHeader>
              <NavMenuSuperHeader onClick={(e) => {e.preventDefault(); setIsOpen(true)}}>Get started</NavMenuSuperHeader>
              <NavCtaWrapper>
                <NavCtaImg src={navCta}/>
                <NavCtaHeader>Why human-first?</NavCtaHeader>
                <NavCtaDesc>Break-through with a personalized, human-first conversation</NavCtaDesc>
                <Button onClick={() => { window.location.href = "why-human-first" }}>Learn more</Button>
              </NavCtaWrapper>
            </DrawerWrapper>
          </Drawer>
        </HeaderRow>
      </Mobile>
    );
  };

  return (
    <HeaderContainer position={position}>
      {renderDialog()}
      <Container>
        <Grid container>
          {renderTableDesktop()}
          {renderMobile()}
        </Grid>
      </Container>
    </HeaderContainer>
  );
};

Header.propTypes = {};

export default Header;
