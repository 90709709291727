import React, { Suspense, lazy }  from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import GlobalStyle from 'styles';

import Home from './Home';
import Marketing from './Marketing';
import Sales from './Sales';
import PrivacyPolicy from './PrivacyPolicy';
import MasterAgreement from './MasterAgreement';
import Terms from './Terms';
import GDPR from './GDPR';
import ABM from './ABM';
import QualifyEngage from './QualifyEngage';
import ConverseContext from './ConverseContext';
import WhyHumanFirst from './WhyHumanFirst';
import Login from './Login';
import MSTeamsConfig from './MSTeamsConfig';
import Marketo from './Marketo';
import Hubspot from './Hubspot';
import Salesforce from './Salesforce';
import LiveChat from './LiveChat';


const App = props => {
  return (
    <div>
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/master-service-agreement" component={MasterAgreement} />
        <Route exact path="/terms-of-service" component={Terms} />
        <Route exact path="/gdpr" component={GDPR} />
        <Route exact path="/marketing-team" component={Marketing} />
        <Route exact path="/sales-team" component={Sales} />
        <Route exact path="/ABM" component={ABM} />
        <Route exact path="/qualify-engage" component={QualifyEngage} />
        <Route exact path="/converse-context" component={ConverseContext} />
        <Route exact path="/why-human-first" component={WhyHumanFirst} />
        <Route exact path="/signin" component={Login} />
        <Route exact path="/ms-teams-config" component={MSTeamsConfig} />
        <Route exact path="/marketo" component={Marketo} />
        <Route exact path="/hubspot" component={Hubspot} />
        <Route exact path="/salesforce" component={Salesforce} />
        <Route exact path="/live-chat" component={LiveChat} />
        <Route path="*" />
      </BrowserRouter>
      <GlobalStyle />
    </div>
  );
};

App.propTypes = {};

export default App;
